import React from "react";

const Order = ({ order }) => {
  return (
    <ul>
      <p>Order ID: {order.id}</p>
      <li>Order Date: {order.order_date}</li>
      <li>Total cost of order: ${order.order_total}</li>
    </ul>
  )
}

export default Order

