import React from "react";
import { BrowserRouter as Router, Routes, Route, Link, useParams } from "react-router-dom"

const Product = ({ product }) => {
  return (
    <ul className="fontNormal">
      <Link className="productTitle navLink" to={'/api/products/' + product.id}>{product.title}</Link>
      <Link to={'/api/products/' + product.id}><img class="testImage" src={product.image} alt={product.title}></img></Link>
      <li>{product.body}</li>
      <li>CATEGORY: {product.category}</li>
      <li>PRICE: ${product.price}</li>
    </ul>
  )
}

export default Product;


