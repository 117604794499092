import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'

const units = [
    {
        id: 1,
        code: "COMP3120",
        name: "Advanced Web Development",
        offering: "S1"
    },
    {
        id: 2,
        code: "COMP1010",
        name: "Fundamentals of Computer Science",
        offering: "S1"
    },
    {
        id: 3,
        code: "COMP1750",
        name: "Introduction to Business Information Systems",
        offering: "S1"
    }
]

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App units={units} />
    </React.StrictMode>
)