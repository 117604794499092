import React from "react";

import { BrowserRouter as Router, Routes, Route, Link, useParams } from "react-router-dom"

const IndividualProduct = ({ indproduct }) => {
  return (
    <p> This is the content for the individual product. </p>
    // <ul className="fontNormal">
    //   {indproduct.title}
    //   <img class="testImage" src={indproduct.image} alt={indproduct.title}></img>
    //   <li>{indproduct.body}</li>
    //   <li>CATEGORY: {indproduct.category}</li>
    //   <li>PRICE: ${indproduct.price}</li>
    // </ul>
  )
}

export default IndividualProduct;


