import React, { useState, useEffect } from "react";
import Product from "./Product.js"
import IndividualProduct from "./IndividualProduct.js";
import Order from "./Order.js"
import axios from 'axios'
import './fonts/PlayfairDisplay-Regular.ttf'
import { BrowserRouter as Router, Routes, Route, Link, useParams } from "react-router-dom"

const Home = () => {
  return (
    <div className="App">
      <h1 className="pageTitle">Welcome!</h1>
      <p className="pageContent fontNormal">Welcome to the landing page for our online store. Use the links at the top of the page to navigate the website!</p>
    </div>
  )
}

const Products = () => {
  const [products, setProducts] = useState([])
  useEffect(() => {
    axios.get("http://localhost:3000/api/products")
      .then((response) => {
        setProducts(response.data)
      })
  }, [])

  return (
    <div className="page">
      <h1 className="pageTitle">PRODUCTS</h1>
      <ul className="gridList">
        {products.map((product) => (<Product key={product.id} product={product} />))}
      </ul>
    </div>
  )
}



const IndividualProducts = () => {
  const [products, setProducts] = useState([])
  var thisIsTheOne
  var indproduct
  const indId = useParams()
  const indIdTitle = indId.id

  useEffect(() => { // this is getting the array from server.js and setting it 
    axios.get("http://localhost:3000/api/products")
      .then((response) => {
        setProducts(response.data)
      })
  }, [])

  useEffect(() => { //this is getting the id from the url 
    axios.get("http://localhost:3000/api/products/" + { indIdTitle })
      .then((response) => {
        indproduct = response.data
      })
  }, [])

  useEffect(() => { // this should set thisistheone to the object in the array matching the id from params
    axios.get("http://localhost:3000/api/products")
      .then((response) => {
        thisIsTheOne = products.find((product) => product.title === indIdTitle)
      })
  }, [])

  return (
    <div className="page">
      <h1 className="pageTitle">Individual Product</h1>
      <p className="pageContent fontNormal">{indIdTitle}</p>
      <p className="pageContent fontNormal"><IndividualProduct indproduct={thisIsTheOne} /></p>
    </div>
  )
}

const Cart = () => {
  return (
    <div className="page">
      <h1 className="pageTitle">CART</h1>
      <ul className="pageContent fontNormal">
        <li>Product 1</li> <button>Delete</button>
        <li>Product 2</li> <button>Delete</button>
        <li>Product 3</li> <button>Delete</button>
        <li>Product 4</li> <button>Delete</button>
      </ul>
      <p className="pageContent fontNormal">Total items: 4<button className="pageContent fontNormal">Confirm Order</button></p>
    </div>
  )
}

const Orders = () => {
  const [orders, setOrders] = useState([])
  useEffect(() => {
    axios.get("http://localhost:3000/api/orders")
      .then((response) => {
        setOrders(response.data)
      })
  }, [])

  return (
    <div className="page">
      <h1 className="pageTitle">ORDERS</h1>
      <ul className="pageContent fontNormal">
        {orders.map((order) => (<Order key={order.id} order={order} />))}
      </ul>
    </div>
  )
}


const App = () => {
  const padding = { padding: 5 }

  return (
    <Router>
      <div className="navBar">
        <Link className="navLink" style={padding} to="/">HOME</Link>
        <Link className="navLink" style={padding} to="/api/products">PRODUCTS</Link>
        <Link className="navLink" style={padding} to="/api/cart">CART</Link>
        <Link className="navLink" style={padding} to="/api/orders">ORDERS</Link>
      </div>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/api/products" element={<Products />} />
        <Route path="/api/products/:id" element={<IndividualProducts />} />
        <Route path="/api/cart" element={<Cart />} />
        <Route path="/api/orders" element={<Orders />} />
      </Routes>
    </Router>
  );
}

export default App;